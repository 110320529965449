.banner {
  color: white;
  object-fit: contain;
  height: 448px;
}

.banner_contents {
  margin-left: 30px;
  padding-top: 210px;
  height: 190px;
}

.banner_title {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.fade__bottom {
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61),
    #111
  );
}

.banner_button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0.2rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
  margin-right: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
}

.banner_button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.2s;
}

.banner_description {
  color: #fff;
  width: 45rem;
  line-height: 1.3rem;
  padding-top: 1rem;
  font-size: 0.8rem;
  max-width: 350px;
  height: 80px;
}
